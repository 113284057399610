import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import "./sla.scss"

export default ({ location }) => (
  <Layout className="policiesPage policiesPage--sla">
    <SEO
      title="LicenseSpring: Service Level Agreement"
      path={location.pathname}
      description={
        "Explore LicenseSpring's service level agreement detailing our commitment to delivering high-quality services and ensuring customer satisfaction."
      }
    />
    <div className="container">
      <h1>License Spring Service Level Agreement</h1>
      <div className="policiesPage__updated">
        <p>
          Effective date: <b>May 29th, 2017</b>
        </p>
      </div>
      <p>
        This License Spring Service Level Agreement (“SLA”) is a policy
        governing the use of License Spring’s cloud-based services (“License
        Spring Services”) under the terms of the License Spring Terms of Service
        (the “Terms of Service”) between Cense Data Inc, and users of License
        Spring’s services (“you”). This SLA applies separately to each account
        using License Spring Services. Unless otherwise provided herein, this
        SLA is subject to the terms of the Terms of Service.{" "}
      </p>
      <h2>1. Service Commitment </h2>
      <p>
        License Spring will use commercially reasonable efforts to make License
        Spring instances available with a Monthly Uptime Percentage (defined
        below) of at least 99.9%, in each case during any monthly billing cycle
        (the “Service Commitment”). In the event License Spring does not meet
        the Service Commitment, you will be eligible to receive a Service Credit
        as described below.{" "}
      </p>
      <h2>2. Definitions </h2>
      <p>The following terms shall be given the meaning set forth below: </p>
      <p>
        <strong>“Monthly Uptime Percentage”</strong> is calculated by
        subtracting from 100% the percentage of continuous 5-minute periods
        during the month in which a License Spring instance was in a state of
        “Unavailable”. Monthly Uptime Percentage measurements exclude downtime
        resulting (directly or indirectly) from any License Spring SLA
        Exclusions defined below.{" "}
      </p>
      <p>
        <strong>“Unavailable”</strong> and <strong>“Unavailability”</strong>{" "}
        means when a running License Spring instance has no external
        connectivity.{" "}
      </p>
      <p>
        A <strong>“Service Credit”</strong> is a credit denominated in USD,
        calculated as set forth below, that we may credit back to an eligible
        account.{" "}
      </p>
      <h2>3. Service Commitments and Service Credits</h2>
      <p>
        Service Credits are calculated as a percentage of the total charges paid
        by you (excluding one-time payments such as upfront payments) for a
        License Spring instance for the monthly billing cycle in which the
        Unavailability occurred in accordance with the schedule below.{" "}
      </p>
      <table>
        <thead>
          <tr>
            <th>Monthly Uptime Percentage</th>
            <th>Service Credit Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td datatype="Monthly Uptime Percentage">
              Less than 99.9% but equal to or greater than 99%:
            </td>
            <td>10%</td>
          </tr>
          <tr>
            <td datatype="Service Credit Percentage">Less than 99%:</td>
            <td>30%</td>
          </tr>
        </tbody>
      </table>
      <p>
        We will apply any Service Credits only against future License Spring
        payments otherwise due from you. At our discretion, we may issue the
        Service Credit to the credit card you used to pay for the billing cycle
        in which the Unavailability occurred. Service Credits will not entitle
        you to any refund or other payment from License Spring. A Service Credit
        will be applicable and issued only if the credit amount for the
        applicable monthly billing cycle is greater than $1USD. Service Credits
        may not be transferred or applied to any other account. Unless otherwise
        provided in the Terms of Service, your sole and exclusive remedy for any
        unavailability, non-performance, or other failure by us to provide
        License Spring Services is the receipt of a Service Credit (if eligible)
        in accordance with the terms of this SLA.{" "}
      </p>
      <h2>4. Credit Request and Payment Procedures </h2>
      <p>
        To receive a Service Credit, you must submit a claim by sending an email
        to support@licensespring.com, or submit a ticket to our help desk at
        https://support.licensespring.com/hc/en-us/requests/new. To be eligible,
        the credit request must be received by us by the end of the second
        billing cycle after which the incident occurred and must include:{" "}
      </p>
      <p>
        If the Monthly Uptime Percentage of such request is confirmed by us and
        is less than the Service Commitment, then we will issue the Service
        Credit to you within one billing cycle following the month in which your
        request is confirmed by us. Your failure to provide the request and
        other information as required above will disqualify you from receiving a
        Service Credit.{" "}
      </p>
      <h2>5. License Spring SLA Exclusions </h2>
      <p>
        The Service Commitment does not apply to any unavailability, suspension
        or termination of License Spring Services, or any other License Spring
        or License Spring Services performance issues:{" "}
      </p>
      <ol>
        <li>
          caused by factors outside of our reasonable control, including,
          without limitation, any force majeure event or Internet access or
          related problems beyond the demarcation point of License Spring
          Services or its direct hosting subcontractors (i.e. beyond the point
          in the network where License Spring maintains access and control over
          the License Spring Services);{" "}
        </li>
        <li>
          that result from any actions or inactions of you or any third party
          (other than License Spring’s direct hosting subcontractor);{" "}
        </li>
        <li>
          that result from your equipment, software or other technology and/or
          third party equipment, software or other technology (other than third
          party equipment within our direct control);{" "}
        </li>
        <li>that result from any scheduled maintenance; </li>
        <li>
          or arising from our suspension and termination of your right to use
          License Spring Services in connection with any breach by you of the
          Terms of Service or otherwise in accordance with the Terms of Service
          (collectively, the “License Spring SLA Exclusions”). If availability
          is impacted by factors other than those used in our Monthly Uptime
          Percentage calculation, then we may issue a Service Credit considering
          such factors at our discretion.{" "}
        </li>
      </ol>
    </div>
  </Layout>
)
